<template>
  <div class="-main-footer-container js-replace-main-footer-container">
    <div>
      <div class="x-footer">
        <div class="container-fluid -footer-wrapper">
          <div class="-footer-inner-wrapper">
            <div class="-describe-wrapper">
              <div class="-content-wrapper">
                <h4 class="-title">
                  คาสิโนออนไลน์ ที่ดีที่สุด<br>ต้องพัฒนาอย่างใส่ใจทุกคลิ๊ก
                </h4>
                <h4 class="-content">
                  บาคาร่า คาสิโนออนไลน์ ที่ดีที่สุด เพื่อประสบการณ์ที่ดีของผู้เล่นอย่างแท้จริง แบบ Casino
                </h4>
              </div>
              <div class="-banner-icon-wrapper">
                <img
                  src="@/assets/newImg/ico/ระบบแรงกว่ามาตรฐานสากล-3-เท่า.png"
                  alt="ระบบคาสิโนปลอดภัย แรงกว่ามาตรฐาน 3 เท่า"
                  data-animatable="fadeInUp"
                  data-delay="200"
                  class="-ic-banner animated fadeInUp"
                ><img
                  src="@/assets/newImg/ico/ฝ่ายบริการลูกค้า-24-7-ชม.png"
                  alt="คาสิโนออนไลน์ที่บริการลูกค้า 24 ชม."
                  data-animatable="fadeInUp"
                  data-delay="400"
                  class="-ic-banner animated fadeInUp"
                ><img
                  src="@/assets/newImg/ico/ระบบฝากถอนเงินออโต้-อัตโนมัติ.png"
                  alt="ฝากถอนเงิน และเครดิตฟรีอัตโนมัติ"
                  data-animatable="fadeInUp"
                  data-delay="600"
                  class="-ic-banner animated fadeInUp"
                >
              </div>
            </div>
            <div class="-tag-wrapper d-none d-lg-block d-xl-block d-xxl-block">
              <div class="-content-wrapper">
                <div class="-title">
                  TAG
                </div>
                <div class="container x-footer-seo">
                  <div class="-tags">
                    <a
                      href="/"
                      class="btn"
                    > casino online</a><a
                      href="/"
                      class="btn"
                    > บาคาร่า</a><a
                      href="/"
                      class="btn"
                    > คาสิโน</a><a
                      href="/"
                      class="btn"
                    > gamecasino</a><a
                      href="/"
                      class="btn"
                    >
                      ออนไลน์ คาสิโน</a><a
                      href="/"
                      class="btn"
                    > game casino</a><a
                      href="/"
                      class="btn"
                    > ae
                      sexy</a><a
                      href="/"
                      class="btn"
                    > เว็บคาสิโน</a><a
                      href="/"
                      class="btn"
                    > ป๊อก เด้ง</a><a
                      href="/"
                      class="btn"
                    > dg casino</a><a
                      href="/"
                      class="btn"
                    > wm casino</a><a
                      href="/"
                      class="btn"
                    > allbet</a><a
                      href="/"
                      class="btn"
                    > sexy gaming</a><a
                      href="/"
                      class="btn"
                    >
                      sa gaming</a><a
                      href="/"
                      class="btn"
                    > คาสิโนออนไลน์</a><a
                      href="/"
                      class="btn"
                    >
                      บาคาร่าสด</a><a
                      href="/"
                      class="btn"
                    > เครดิตฟรี</a><a
                      href="/"
                      class="btn"
                    > บาคาร่า</a><a
                      href="/"
                      class="btn"
                    > เสือ มังกร</a><a
                      href="/"
                      class="btn"
                    > บาคาร่าออนไลน์</a><a
                      href="/"
                      class="btn"
                    > เกมไพ่</a><a
                      href="/"
                      class="btn"
                    > sagame</a><a
                      href="/"
                      class="btn"
                    >
                      คาสิโน</a><a
                      href="/"
                      class="btn"
                    > casino</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="-provider-wrapper">
              <div class="-content-wrapper -payments">
                <div class="-title">
                  PAYMENTS ACCEPTED
                </div>
                <div class="x-footer-banner-provider">

                  <div class="-image-wrapper ng-star-inserted">
                    <img
                      alt="CasinoPayments Accepted bay"
                      width="50"
                      height="50"
                      class="-logo-ic img-fluid rounded-circle"
                      src="@/assets/newImg/bank/bbl.png"
                    >
                  </div>
                  <div class="-image-wrapper ng-star-inserted">
                    <img
                      alt="CasinoPayments Accepted bay"
                      width="50"
                      height="50"
                      class="-logo-ic img-fluid rounded-circle"
                      src="@/assets/newImg/bank/kbank.png"
                    >
                  </div>
                  <div class="-image-wrapper ng-star-inserted">
                    <img
                      alt="CasinoPayments Accepted bay"
                      width="50"
                      height="50"
                      class="-logo-ic img-fluid rounded-circle"
                      src="@/assets/newImg/bank/ktb.png"
                    >
                  </div>
                  <div class="-image-wrapper ng-star-inserted">
                    <img
                      alt="CasinoPayments Accepted bay"
                      width="50"
                      height="50"
                      class="-logo-ic img-fluid rounded-circle"
                      src="@/assets/newImg/bank/tmb.png"
                    >
                  </div>
                  <div class="-image-wrapper ng-star-inserted">
                    <img
                      alt="CasinoPayments Accepted bay"
                      width="50"
                      height="50"
                      class="-logo-ic img-fluid rounded-circle"
                      src="@/assets/newImg/bank/scb.png"
                    >
                  </div>
                  <div class="-image-wrapper ng-star-inserted">
                    <img
                      alt="CasinoPayments Accepted bay"
                      width="50"
                      height="50"
                      class="-logo-ic img-fluid rounded-circle"
                      src="@/assets/newImg/bank/cimb.png"
                    >
                  </div>
                  <div class="-image-wrapper ng-star-inserted">
                    <img
                      alt="CasinoPayments Accepted bay"
                      width="50"
                      height="50"
                      class="-logo-ic img-fluid rounded-circle"
                      src="@/assets/newImg/bank/uob.png"
                    >
                  </div>
                  <div class="-image-wrapper ng-star-inserted">
                    <img
                      alt="CasinoPayments Accepted bay"
                      width="50"
                      height="50"
                      class="-logo-ic img-fluid rounded-circle"
                      src="@/assets/newImg/bank/bay.png"
                    >
                  </div>
                  <div class="-image-wrapper ng-star-inserted">
                    <img
                      alt="CasinoPayments Accepted bay"
                      width="50"
                      height="50"
                      class="-logo-ic img-fluid rounded-circle"
                      src="@/assets/newImg/bank/gsb.png"
                    >
                  </div>
                  <div class="-image-wrapper ng-star-inserted">
                    <img
                      alt="CasinoPayments Accepted bay"
                      width="50"
                      height="50"
                      class="-logo-ic img-fluid rounded-circle"
                      src="@/assets/newImg/bank/BAAC.png"
                    >
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div class="-footer-bottom-wrapper">
            <div class="row justify-content-center m-0">
              <div class="col-md-6 text-center mt-4">
                Created website by Dragons168
                <div class="text-center ng-star-inserted" />
              </div>
            </div>
            <div>
              <a
                href="/term-and-condition"
                target="_blank"
                class="-term-btn"
              > Terms
                and Conditions </a>
            </div>
            <div>
              <p class="mb-0">
                Copyright © 2024 All Rights Reserved.
              </p>
              <p class="opacity-50 ng-star-inserted">
                <small>version 1.9.25 Pro</small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

</style>
