<template>
  <div>
    <div id="main__content">
      <div class="x-main-container">
        <div class="x-main-side-bar">
          <div class="x-menu-provider js-tab-menu-provider -desktop-view">
            <nav
              id="navbarCategory"
              class="nav-menu"
            >
              <ul class="nav nav-pills js-menu-container -nav-menu-container">
                <li
                  v-for="(item, index) in gameListOp"
                  :key="index"
                  class="nav-item ng-star-inserted"
                >
                  <button
                    :v-if="menu[index] == true"
                    class="nav-link-btn -casino"
                    @click="getProduct(item.cat)"
                  >
                    <div
                      class="rtx-left"
                      :class="`${menuActive === item.cat ? '-bg-casino' : '-bg-none'}`"
                    />

                    <div
                      class="rtx-right"
                      :class="`${menuActive === item.cat ? '-bg-casino' : '-bg-none'}`"
                    />
                    <img
                      alt="สล็อตเกม"
                      width="55"
                      height="55"
                      class="img-fluid -ic-menu"
                      :class="`${menuActive === item.cat ? 'shaking' : ''}`"
                      :src="item.icon"
                    >
                    <div class="-text-provider-wrapper">
                      <div class="-text-nav-menu -title">
                        {{ item.name_en }}
                      </div>
                      <div class="-text-nav-menu -title-trans">
                        {{ item.name_th }}
                      </div>
                    </div>
                  </button>
                </li>
              </ul>
              <div class="-contact-wrapper">
                <div class="x-contact-us -text">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    class="-line-wrapper"
                    :href="agent.linecontact"
                  >
                    <img
                      src="@/assets/newImg/ic-line-text.png"
                      alt="รูปไอคอนไลน์"
                      width="160"
                      height="51"
                      class="-line-img"
                    ></a>
                </div>
              </div>
            </nav>
          </div>
        </div>
        <div class="x-main-content">
          <div class="d-none d-lg-block">
            <div class="x-feed-news-header">
              <div
                data-animatable="fadeInUp"
                data-delat="200"
                class="-feed-news-inner-wrapper animated fadeInUp"
              >
                <!-- <div class="-icon-container">
                  <i class="fas fa-volume" />
                </div> -->
                <div class="-track">
                  <div class="-track-item -duration-normal-content">
                    ...
                    {{ agent.announce }}
                    &nbsp;..
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section class="x-index-top-container position-relative pb-4">
            <div
              class="bg-main"
              style="background-image: url('/bg-rich.jpg') !important; background-attachment: fixed; background-size: cover;"
            />
            <div class="x-wrapper-right-container">
              <div class="-index-inner-wrapper">
                <div class="d-block d-md-none d-lg-none">
                  <swiper
                    v-show="isShow"
                    class="swiper-autoplay"
                    :options="swiperOptions"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  >
                    <swiper-slide
                      v-for="data in swiperData"
                      :key="data.img"
                    >
                      <b-img
                        :src="data.img"
                        fluid
                        style="border-radius: 10px"
                      />
                    </swiper-slide>
                  </swiper>
                </div>
                <div class="d-lg-none">
                  <div class="x-feed-news-header">
                    <div
                      data-animatable="fadeInUp"
                      data-delat="200"
                      class="-feed-news-inner-wrapper animated fadeInUp"
                    >
                      <div class="-icon-container">
                        <i class="fa fa-volume-up" />
                      </div>
                      <div class="-track">
                        <div class="-track-item -duration-normal-content">
                          ...
                          {{ agent.announce }}
                          &nbsp;..
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="ng-star-inserted">
                  <div class="-games-provider-wrapper">
                    <div
                      class="-menu-index-page"
                      style="position: relative; overflow: hidden;"
                    >
                      <div class="sticksy-dummy-node">
                        <div class="x-menu-provider js-tab-menu-provider">
                          <nav
                            id="navbarCategory"
                            class="nav-menu"
                          >
                            <ul class="nav nav-pills js-menu-container -nav-menu-container">
                              <li
                                v-for="item in gameListOp"
                                :key="item.id"
                                class="nav-item ng-star-inserted"
                              >
                                <button
                                  class="nav-link-btn-mobile"
                                  @click="getProduct(item.cat)"
                                >
                                  <div
                                    class="rtx-left-mobile"
                                    :class="`${menuActive === item.cat ? '-bg-casino' : '-bg-none'}`"
                                  />

                                  <div
                                    class="rtx-right-mobile"
                                    :class="`${menuActive === item.cat ? '-bg-casino' : '-bg-none'}`"
                                  />
                                  <img
                                    :alt="item.alt"
                                    width="55"
                                    height="55"
                                    class="img-fluid -ic-menu"
                                    :class="`${menuActive === item.cat ? 'shaking' : ''}`"
                                    :src="item.icon"
                                  >
                                  <div class="-text-provider-wrapper">
                                    <!-- <div class="-text-nav-menu -title">
                                      {{ item.name_en }}
                                    </div> -->
                                    <div class="-text-nav-menu -title-mobile">
                                      {{ item.name_th }}
                                    </div>
                                  </div>
                                </button>
                              </li>
                            </ul>
                            <div class="-contact-wrapper">
                              <div class="x-contact-us -text">
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  class="-line-wrapper"
                                  :href="agent.linecontact"
                                ><img
                                  src="@/assets/newImg/ic-line-text.png"
                                  alt="รูปไอคอนไลน์"
                                  width="160"
                                  height="51"
                                  class="-line-img"
                                ></a>
                              </div>
                            </div>
                          </nav>
                        </div>
                      </div>
                    </div>
                    <div class="-games-index-page">
                      <div class="x-category-provider js-game-scroll-container js-game-container">
                        <div v-if="!gameList.length">
                          <h3 class="mb-0 p-1">
                            Dragons168 คาสิโน บาคาร่า เว็บตรงที่มาแรงทีสุดในประเทศไทย
                          </h3>
                          <VueSlickCarousel
                            v-if="isShow"
                            :arrows="false"
                            :dots="true"
                            v-bind="OptionSlider"
                            class="d-none d-md-block d-lg-block"
                          >
                            <div
                              v-for="data in swiperData"
                              :key="data.img"
                              class="px-1"
                            >

                              <b-img
                                :src="data.img"
                                fluid
                                style="border-radius: 18px !important;"
                              />
                            </div>
                          </VueSlickCarousel>
                        </div>
                        <div class="-games-list-container container">
                          <div class="x-hot-games-container">
                            <div class="ng-star-inserted">
                              <div class="x-category-provider js-game-scroll-container js-game-container">
                                <!-- <div class="-top-paragraph">
                                  <div class="container">
                                    <h2 class="-h2">
                                      <span>LOTTO</span> ที่ดีที่สุดสำหรับคุณแบบง่ายๆ
                                      ที่จะช่วยให้คุณได้เว็บซื้อหวยออนไลน์ บน Power100TH
                                    </h2>
                                    <p class="-h3">
                                      แทงหวยได้หลายประเภท เพิ่มอัตราจ่ายเมื่อถูกรางวัลสูงขึ้น
                                    </p>
                                  </div>
                                </div> -->
                                <div v-if="!gameList.length">

                                  <div class="-bottom-paragraph">
                                    <img
                                      src="/logo/Gdragons.png"
                                      alt="logo-Dragons168"
                                      width="200"
                                    >
                                    <h3 class="-text mt-2">
                                      <span class="-highlight">Dragons168</span> เว็บที่ให้บริการคาสิโนออนไลน์เต็มรูปแบบ
                                      <br> ทั้งสล็อตออนไลน์ คาสิโนสด บาคาร่าสด เกมแทงปลา เกมกีฬา ฯลฯ
                                    </h3>
                                    <p class="-text -paragraph -h3">
                                      สามารถทำการฝาก-ถอนได้ตลอด 24 ชั่วโมง ด้วยระบบออโต้ ทั้งสะดวก
                                      รวดเร็วทันใจเป็นอย่างมากเหมาะสำหรับผู้ที่ชื่นชอบการเดิมพันในรูปแบบของเกมการพนันออนไลน์อันดับ
                                      1 ในปี 2024
                                    </p>

                                    <!-- <swiper
                                      class="swiper-autoplay mt-2 rounded-3 d-none d-md-block d-lg-block"
                                      :options="swiperOptions"
                                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    >
                                      <swiper-slide
                                        v-for="data in swiperData"
                                        :key="data.img"
                                      >
                                        <b-img
                                          :src="data.img"
                                          fluid
                                          style="border-radius: 10px"
                                        />
                                      </swiper-slide>
                                    </swiper> -->
                                  </div>
                                </div>

                                <div class="-games-list-container container">
                                  <nav
                                    id="navbarProvider"
                                    class="nav-menu"
                                  >
                                    <ul class="nav nav-pills">
                                      <li
                                        v-for="games in gameList"
                                        :key="games.id"
                                        class="nav-item -game-casino-macro-container ng-star-inserted"
                                      >
                                        <div
                                          v-if="games.type === 'FISHING'"
                                          class="x-game-list-item-macro js-game-list-toggle -big"
                                          @click="playGame(games)"
                                        >
                                          <div class="-inner-wrapper">
                                            <img
                                              class="-cover-img img-fluid ls-is-cached lazyloaded"
                                              :src="games.img"
                                              :alt="games.name"
                                            >
                                            <div class="-overlay">
                                              <div class="-overlay-inner">
                                                <div class="-wrapper-container"><a
                                                  class="-btn -btn-play js-account-approve-aware"
                                                  @click="playGame(games)"
                                                ><i class="fas fa-play" /><span
                                                  class="-text-btn"
                                                >เข้าเล่น</span></a></div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="-title text-center">
                                            {{ games.name }}
                                          </div>
                                        </div>

                                        <div
                                          v-else
                                          class="x-game-list-item-macro js-game-list-toggle -big"
                                          @click="playGame(games)"
                                        >
                                          <div class="-inner-wrapper">
                                            <img
                                              class="-cover-img bg-back img-fluid ls-is-cached lazyloaded"
                                              :src="games.img_url"
                                              :alt="games.productName"
                                            >
                                            <div class="-overlay">
                                              <div class="-overlay-inner">
                                                <div class="-wrapper-container"><a
                                                  class="-btn -btn-play js-account-approve-aware"
                                                  @click="playGame(games)"
                                                ><i class="fas fa-play" /><span
                                                  class="-text-btn"
                                                >เข้าเล่น</span></a></div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="-title text-center">
                                            {{ games.productName }}
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </nav>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <feed-footer />
          <!-- <this-footer /> -->
        </div>
      </div>
    </div>

    <b-modal
      ref="my-modal"
      hide-footer
      centered
      hide-header
      class="modals"
      style="background-color: transparent !important;"
    >
      <div class="text-center">
        <router-link :to="{name : 'promotions'}">
          <b-img
            src="/modal/songkran.png"
            alt="songkran"
            fluid
          />

        </router-link>
        <button
          class="btn-closed"
          @click="hidenModal"
        >
          <i class="fas fa-times" />
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import { BCard, BCardText, BLink } from 'bootstrap-vue'
import { BImg, BModal } from 'bootstrap-vue'
// eslint-disable-next-line import/order
import FeedFooter from './components/FeedFooter.vue'
import moment from 'moment'
import 'swiper/css/swiper.css'
import BUID from 'uniquebrowserid'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
// import { ThisFooter } from './ThisFooter.vue'
// import { t } from '@/@core/libs/i18n/utils'

export default {
  components: {
    Swiper,
    SwiperSlide,
    BImg,
    BModal,
    VueSlickCarousel,
    FeedFooter,
    // BCard,
    // BCardText,
    // BLink,
    // ThisFooter,
  },
  data() {
    return {
      isShow: false,
      menuActive: '',
      gameListOp: [
        /* eslint-disable global-require */
        {
          icon: require('@/assets/newImg/slot.png'),
          name_th: 'สล็อตเกม',
          name_en: 'SLOT',
          alt: 'สล็อตเกม',
          cat: 'EGAMES',
        },
        {
          icon: require('@/assets/newImg/คาสิโนสด-1.webp'),
          name_th: 'คาสิโนสด',
          name_en: 'CASINO',
          alt: 'คาสิโนสด',
          cat: 'LIVECASINO',
        },
        {
          icon: require('@/assets/newImg/กีฬา-1.webp'),
          name_th: 'กีฬา',
          name_en: 'SPORT',
          alt: 'กีฬา',
          cat: 'SPORT',
        },
        {
          icon: require('@/assets/newImg/หวย-1.webp'),
          name_th: 'หวย',
          name_en: 'LOTTO',
          alt: 'หวย',
          cat: 'LOTTO',
        },
        {
          icon: require('@/assets/newImg/quick-nav-mpg.png'),
          name_th: 'ยิงปลา',
          name_en: 'FISHING',
          alt: 'ยิงปลา',
          cat: 'FISHING',
        },
        {
          icon: require('@/assets/newImg/คาสิโนออนไลน์-บาคาร่าสด-รูเล็ต-ซิคโบ-ไฮโล-เสือ-มังกร.png'),
          name_th: 'เกมการ์ด',
          name_en: 'G-CARD',
          alt: 'เกมการ์ด',
          cat: 'CARD',
        },
        {
          icon: require('@/assets/newImg/poker.png'),
          name_th: 'เกมโป๊กเกอร์',
          name_en: 'POKER',
          alt: 'โป๊กเกอร์',
          cat: 'POKER',
        },
        {
          icon: require('@/assets/newImg/trading.png'),
          name_th: 'เทรดดิ้ง',
          name_en: 'TRADING',
          alt: 'เทรดดิ้ง',
          cat: 'TRADING',
        },
        /* eslint-disable global-require */
      ],
      gameList: [],
      gameListL: [],
      agent: {},
      swiperOptions: {
        centeredSlides: true,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
      },
      /* eslint-disable global-require */
      swiperData: [
        { img: '/slider/ชวนเพื่อน10เปอร์เซ็นต์_power100.jpg' },
        { img: '/slider/คืนยอดเสีย_power100.jpg' },
        { img: '/slider/สมัครใหม่100เปอร์เซ็นต์_power100.jpg' },
        { img: '/slider/สมัครใหม่50เปอร์เซ็นต์_power100.jpg' },
        { img: '/slider/ทุกยอดฝาก10เปอร์เซ็นต์_power100.jpg' },
        { img: '/slider/ฝากบิลแรก20เปอร์เซ็นต์_power100.jpg' },
      ],
      OptionSlider: {
        centerMode: true,
        // centerPadding: '20px',
        focusOnSelect: true,
        infinite: true,
        slidesToShow: 3,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        dots: true,
      },
      /* eslint-disable global-require */
      userData: JSON.parse(localStorage.getItem('userData')),
      balance: 0.0,
      turnpro: '',
      turn: '',
      today: moment(new Date()).format('DD/MM/YYYY'),
      timenow: moment(new Date()).format('HH:mm'),
      message: 'asdasd',
      User: '',
      selectCategory: false,
      logo_path: localStorage.getItem('logo_path'),
      menu: [true, true, true, true, true, true, true, true],
      checkgame: [],
    }
  },
  mounted() {
    // this.openModal()
    this.GetMenu()
    this.GetAgentData()
    this.getbalance()
    this.lastlogin()
    this.loginstampAtdays()
    this.updateBUID()
    // this.showModal()
  },
  methods: {
    // showModal() {
    //   this.$refs['my-modal'].show()
    // },
    GetMenu() {
      this.$http
        .get('/game/menu')
        .then(response => {
          // Menu
          response.data.forEach((element, index) => {
            const userAgentId = this.userData.agent_id
            if (element.agent_use) {
              const agentUseArray = element.agent_use.split(',').map(agentId => parseInt(agentId.trim(), 10))
              if (agentUseArray.includes(userAgentId)) {
                this.menu[index] = true
              } else {
                this.menu[index] = false
              }
            } else {
              this.menu[index] = false
            }
          })
        })
        .catch(error => console.log(error))
    },
    hidenModal() {
      this.$refs['my-modal'].hide()
    },
    getbalance() {
      this.show = true
      this.$http
        .get('/balance/getbalance')
        .then(response => {
          console.log(response.data)
          this.show = false
          this.balance = response.data.balance
          if (this.balance < 0) {
            this.balance = 0
          }
          this.turn = response.data
        })
        .catch(error => console.log(error))
    },
    CheckPass() {
      if (btoa(this.password) === 'UEBwb3dlcjEwMA==') {
        this.LottoLogin()
      }
    },
    LottoLogin() {
      this.show = true
      const obj = {
      }
      this.$http
        .post('/rmtlotto/login', obj)
        .then(response => {
          console.log(response.data)
          this.show = false
          window.location.href = response.data.url
        })
        .catch(error => console.log(error))
    },
    play(productId, gameCode) {
      // console.log(this.$route.params.productId)88
      const obj = {
        username: this.userData.username,
        productId,
        gameCode,
        sessionToken: this.userData.token,
      }
      this.$http
        .post('/product/login', obj)
        .then(response => {
          // console.log(response.data.data.url)
          this.show = false
          window.location.href = response.data.data.url
        })
        .catch(error => console.log(error))
    },
    playGame(games) {
      if (this.userData.status && this.userData.status === 2) {
        this.$swal({
          icon: 'info',
          title: '<h3 style="color: #FFF">แจ้งเตือน !</h3>',
          text: 'บัญชีของท่านถูกระงับ',
          showConfirmButton: false,
          timer: 5000,
          background: '#202124',
        })
      } else if (games.type === 'FISHING') {
        this.play(games.productId, games.code)
      } else if (Number(this.turn.turnover) > 0) {
        if (games.category === 'EGAMES') {
          this.$router.push({
            name: 'games-all',
            params: {
              productId: games.productId,
              productName: games.productName,
            },
          })
        } else {
          this.SwalError('ท่านมีโปรฯที่ยังทำเทิร์นไม่ครบ ไม่สามารถเล่นเกมนี้ได้ค่ะ')
        }
      } else if (games.category === 'SPORT') {
        if (games.productId === 'SBOBET') {
          this.show = true
          const obj = {
          }
          this.$http
            .post('/sbobet/login', obj)
            .then(response => {
              console.log(response.data)
              this.show = false
              window.location.href = response.data.url
            })
            .catch(error => console.log(error))
          // this.SwalError('พบกับ SBOBET ที่นี่เร็วๆนี้')
        }
        if (games.productId === 'E1SPORT') {
          this.play(games.productId, 'E1-ESPORTS-001')
        }
      } else if (games.category === 'LOTTO') {
        if (games.productId === 'LOTTO') {
        // Modal
          // this.showModal()
          this.LottoLogin()
        }
      } else {
        this.$router.push({
          name: 'games-all',
          params: {
            productId: games.productId,
            productName: games.productName,
          },
        })
      }
    },
    getProductAll() {
      this.initvalue()
      this.$http
        .get('/product/listall')
        .then(response => {
          // console.log(response.data)
          this.show = false
          this.gameList = response.data
        })
        .catch(error => console.log(error))
      this.$http
        .get('/product/listalll')
        .then(response => {
          // console.log(response.data)
          this.show = false
          this.gameListL = response.data
        })
        .catch(error => console.log(error))
    },
    getProduct(category) {
      this.menuActive = category
      this.checkgame = []
      this.gameList = []
      this.initvalue()
      this.$http
        .get(`/product/listcat/${category}`)
        .then(response => {
          this.show = false
          this.gameList = response.data
          this.gameList.forEach((element, index) => {
            const userAgentId = this.userData.agent_id
            if (element.agent_use) {
              const agentUseArray = element.agent_use.split(',').map(agentId => parseInt(agentId.trim(), 10))
              if (agentUseArray.includes(userAgentId)) {
                this.checkgame[index] = true
              } else {
                this.checkgame[index] = false
                this.gameList.splice(index, 1)
              }
            } else if (element.type && element.type === 'FISHING') {
              this.checkgame[index] = true
            } else {
              this.checkgame[index] = false
              this.gameList.splice(index, 1)
            }
          })
        })
        .catch(error => console.log(error))
      this.$http
        .get(`/product/listcatl/${category}`)
        .then(response => {
          // console.log(response.data)
          this.show = false
          this.gameListL = response.data
        })
        .catch(error => console.log(error))
    },
    GetAgentData() {
      this.$http
        .get('/agent/show')
        .then(response => {
          // console.log(response.data)
          this.agent = response.data
        })
        .catch(error => console.log(error))
    },

    updateBUID() {
      const buid = new BUID().completeID()
      const formData = {
        buid,
      }
      this.$http.post('/users/updatebuid', formData)
    },
    lastlogin() {
      this.$http
        .get('/users/lastlogin')
        // eslint-disable-next-line no-unused-vars
        .then(response => {

        })
        .catch(error => console.log(error))
    },
    loginstampAtdays() {
      this.$http
        .get('/users/loginstampAtdays')
        // eslint-disable-next-line no-unused-vars
        .then(response => {

        })
        .catch(error => console.log(error))
    },
    comingSoon() {
      this.$swal({
        icon: 'info',
        title: '<h3 style="color: #FFF">Coming soon!</h3>',
        showConfirmButton: false,
        timer: 1500,
        background: '#202124',
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color:#000">ขออภัยค่ะ!<span>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    initvalue() {
      this.gameList = []
      this.gameListL = []
    },
  },
}
</script>
<style scoped>
.bg-back {
  background-image: url('/bg-games.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
</style>
